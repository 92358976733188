import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Testimonials from "../components/testimonials-list";

const TestimonialsPage = () => {
	// const [reviews, setReviews] = useState([]);

	// useEffect(() => {
	//   axios
	//     .get(
	//       `https://www.local-marketing-reports.com/external/showcase-reviews/widgets/19d4f28889860f7faaef66602d649c33f833b6bb`
	//     )
	//     .then((res) => {
	//       const reviewData = res.data.results;
	//       setReviews(reviewData);
	//       setReviews(reviewData);
	//       document.getElementById("loading-spinner").style.display = "none";
	//       document.getElementById("no-reviews").style.display = "block";
	//     });
	// });

	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "jason-client-meeting-5.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Written Testimonials",
				item: {
					url: `${siteUrl}/written-testimonials`,
					id: `${siteUrl}/written-testimonials`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Written Testimonials | Jason Cornes"
				description="Most of us could benefit from another point of view, a guiding hand, or an experienced businessperson to be a ‘sounding board’ and hold us accountable."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/written-testimonials`,
					title: "Written Testimonials | Jason Cornes",
					description:
						"Most of us could benefit from another point of view, a guiding hand, or an experienced businessperson to be a ‘sounding board’ and hold us accountable.",
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Jason Cornes Business Coaching Testimonials",
						},
					],
				}}
			/>
			<Container className="pb-5">
				<Row className="pt-5 text-center">
					<Col className="text-left">
						<h1 className="text-center">
							Business & Executive Coaching Written Testimonials
						</h1>
						<hr className="bg-secondary text-center w-25 " />
						<p className="pt-3">
							I’m pleased and privileged to have helped so many clients become
							the successful business or practice owners they were meant to be.
							Therefore, I thought I’d include some written Business & Executive
							Coaching client testimonials for you to see that I put my money
							where my mouth is, so to speak! Every single one of us is
							different and my coaching will be tailored to your current
							circumstances, needs and requirements.
						</p>
						<p>
							Most of us could benefit from another point of view, a guiding
							hand, or an experienced businessperson to be a ‘sounding board’
							and hold us accountable.
						</p>
						<p>
							Please read more about how I’ve delivered just those very things.
							You might also like to visit my{" "}
							<Link to="/video-testimonials">Video Testimonials</Link> page.
						</p>
					</Col>
				</Row>

				<Testimonials />

				{/* <Row> */}
				{/* <Col xs={12}>
            <p
              className="text-center"
              id="no-reviews"
              style={{ display: "none" }}
            >
              <em>Rated 4.9 / 5 across {reviews.length} Reviews</em>
            </p>
          </Col>
          <Col className="d-flex justify-content-center" xs={12}>
            <span className="d-block" />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Spinner id="loading-spinner" variant="primary" animation="border" />

          {reviews.map((review) => (
            <Col xs={11} sm={10} xl={6}>
              <Row className="align-items-center">
                <Col xs={12} className="">                 
                  <p className="">
                    <span className="">&ldquo;</span>
                    {review.reviewBody}
                    <span className="">&rdquo;</span>
                  </p>
                  <p className="">
                    <span className="">-</span> {review.author}
                  </p>
                </Col>
              </Row>
            </Col>
          ))}
        </Row> */}
			</Container>
		</Layout>
	);
};

export default TestimonialsPage;
